import React from "react";

const PastTimer = ({ duration }) => {
  const formatDuration = (seconds) => {
    const units = [
      { label: "year", value: 31536000 },
      { label: "month", value: 2592000 },
      { label: "day", value: 86400 },
      { label: "hour", value: 3600 },
      { label: "minute", value: 60 },
      { label: "second", value: 1 },
    ];

    const result = [];
    for (const unit of units) {
      const quotient = Math.floor(seconds / unit.value);
      if (quotient > 0) {
        result.push(`${quotient} ${unit.label}${quotient > 1 ? "s" : ""}`);
        seconds %= unit.value;
      }
      if (result.length === 2) break;
    }

    return result.join(", ");
  };

  return <div>{formatDuration(duration / 1000)} ago</div>;
};

export default PastTimer;
